









import { Component, Vue } from 'vue-property-decorator';
import BasicTimeZone from './BasicTimeZone.vue';

@Component({
  components: {
    BasicTimeZone
  }
})
export default class Inventory extends Vue {}
