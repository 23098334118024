export const timeZonesList = [
  {
    text: '(GMT-10) Hawaii',
    value: 'US/Hawaii'
  },
  {
    text: '(GMT-8) Alaska',
    value: 'US/Alaska'
  },
  {
    text: '(GMT-7) Pacific Time (US & Canada)',
    value: 'US/Pacific'
  },
  {
    text: '(GMT-7) Arizona',
    value: 'US/Arizona'
  },
  {
    text: '(GMT-6) Mountain Time (US & Canada)',
    value: 'US/Mountain'
  },
  {
    text: '(GMT-5) Central Time (US & Canada)',
    value: 'US/Central'
  },
  {
    text: '(GMT-4) Eastern Time (US & Canada)',
    value: 'US/Eastern'
  },
  {
    text: '(GMT-4) Indiana (East)',
    value: 'US/East-Indiana'
  },
  {
    text: '(GMT-11) International Date Line West',
    value: 'Pacific/Midway'
  },
  {
    text: '(GMT-11) Midway Island',
    value: 'Pacific/Midway'
  },
  {
    text: '(GMT-11) Samoa',
    value: 'Pacific/Samoa'
  },
  {
    text: '(GMT-10) Hawaii',
    value: 'US/Hawaii'
  },
  {
    text: '(GMT-8) Alaska',
    value: 'US/Alaska'
  },
  {
    text: '(GMT-7) Pacific Time (US & Canada)',
    value: 'US/Pacific'
  },
  {
    text: '(GMT-7) Tijuana',
    value: 'America/Tijuana'
  },
  {
    text: '(GMT-7) Arizona',
    value: 'US/Arizona'
  },
  {
    text: '(GMT-6) Chihuahua',
    value: 'America/Chihuahua'
  },
  {
    text: '(GMT-6) Mazatlan',
    value: 'America/Mazatlan'
  },
  {
    text: '(GMT-6) Mountain Time (US & Canada)',
    value: 'US/Mountain'
  },
  {
    text: '(GMT-6) Saskatchewan',
    value: 'Canada/Saskatchewan'
  },

  {
    text: '(GMT-5) Central America',
    value: 'Canada/Central'
  },

  {
    text: '(GMT-5) Guadalajara',
    value: 'Mexico/General'
  },
  {
    text: '(GMT-5) Mexico City',
    value: 'Mexico/General'
  },
  {
    text: '(GMT-5) Monterrey',
    value: 'America/Monterrey'
  },
  {
    text: '(GMT-5) Bogota',
    value: 'America/Bogota'
  },
  {
    text: '(GMT-5) Lima',
    value: 'America/Lima'
  },
  {
    text: '(GMT-5) Rio Branco',
    value: 'America/Rio_Branco'
  },
  {
    text: '(GMT-5) Quito',
    value: 'Etc/GMT+5'
  },
  {
    text: '(GMT-4) Eastern Time (US & Canada)',
    value: 'US/Eastern'
  },
  {
    text: '(GMT-4) Indiana (East)',
    value: 'US/East-Indiana'
  },
  {
    text: '(GMT-4) Caracas',
    value: 'America/Caracas'
  },
  {
    text: '(GMT-4) La Paz',
    value: 'Etc/GMT+4'
  },
  {
    text: '(GMT-4) Cuiaba',
    value: 'America/Cuiaba'
  },
  {
    text: '(GMT-4) Manaus',
    value: 'America/Manaus'
  },
  {
    text: '(GMT-4) Santiago',
    value: 'America/Santiago'
  },
  {
    text: '(GMT-4) Mato Grosso',
    value: 'America/Cuiaba'
  },
  {
    text: '(GMT-4) Georgetown',
    value: 'America/Guyana'
  },
  {
    text: '(GMT-3) Atlantic Time (Canada)',
    value: 'Canada/Atlantic'
  },
  {
    text: '(GMT-3) Buenos Aires',
    value: 'America/Argentina/Buenos_Aires'
  },
  {
    text: '(GMT-3) NE Brazil, Fortaleza',
    value: 'America/Fortaleza'
  },
  {
    text: '(GMT-3) Brasilia, Sao Paulo',
    value: 'America/Sao_Paulo'
  },
  {
    text: '(GMT-2) Greenland',
    value: 'America/Godthab'
  },
  {
    text: '(GMT-2) Fernando de Noronha',
    value: 'America/Noronha'
  },
  {
    text: '(GMT-1) Cape Verde Is. ',
    value: 'Atlantic/Cape_Verde'
  },
  {
    text: '(GMT+0) Azores',
    value: 'Atlantic/Azores'
  },
  {
    text: '(GMT+0) Casablanca',
    value: 'Africa/Casablanca'
  },
  {
    text: '(GMT+1) Dublin',
    value: 'Europe/Dublin'
  },
  {
    text: '(GMT+1) Edinburgh',
    value: 'Europe/London'
  },
  {
    text: '(GMT+1) Lisbon',
    value: 'Europe/Lisbon'
  },
  {
    text: '(GMT+1) London',
    value: 'Europe/London'
  },
  {
    text: '(GMT+0) Monrovia',
    value: 'Africa/Monrovia'
  },
  {
    text: '(GMT+0) UTC',
    value: 'UTC'
  },
  {
    text: '(GMT+2) Amsterdam',
    value: 'Europe/Amsterdam'
  },
  {
    text: '(GMT+2) Belgrade',
    value: 'Europe/Belgrade'
  },
  {
    text: '(GMT+2) Berlin',
    value: 'Europe/Berlin'
  },
  {
    text: '(GMT+2) Bern',
    value: 'Europe/Zurich'
  },
  {
    text: '(GMT+2) Bratislava',
    value: 'Europe/Bratislava'
  },
  {
    text: '(GMT+2) Brussels',
    value: 'Europe/Brussels'
  },
  {
    text: '(GMT+2) Budapest',
    value: 'Europe/Budapest'
  },
  {
    text: '(GMT+2) Copenhagen',
    value: 'Europe/Copenhagen'
  },
  {
    text: '(GMT+2) Ljubljana',
    value: 'Europe/Ljubljana'
  },
  {
    text: '(GMT+2) Madrid',
    value: 'Europe/Madrid'
  },
  {
    text: '(GMT+2) Paris',
    value: 'Europe/Paris'
  },
  {
    text: '(GMT+2) Prague',
    value: 'Europe/Prague'
  },
  {
    text: '(GMT+2) Rome',
    value: 'Europe/Rome'
  },
  {
    text: '(GMT+2) Sarajevo',
    value: 'Europe/Sarajevo'
  },
  {
    text: '(GMT+2) Skopje',
    value: 'Europe/Skopje'
  },
  {
    text: '(GMT+2) Stockholm',
    value: 'Europe/Stockholm'
  },
  {
    text: '(GMT+2) Vienna',
    value: 'Europe/Vienna'
  },
  {
    text: '(GMT+2) Warsaw',
    value: 'Europe/Warsaw'
  },
  {
    text: '(GMT+2) Zagreb',
    value: 'Europe/Zagreb'
  },
  {
    text: '(GMT+2) Cairo',
    value: 'Africa/Cairo'
  },
  {
    text: '(GMT+2) Harare',
    value: 'Africa/Harare'
  },
  {
    text: '(GMT+2) Pretoria',
    value: 'Africa/Johannesburg'
  },
  {
    text: '(GMT+3) Athens',
    value: 'Europe/Athens'
  },
  {
    text: '(GMT+3) Bucharest',
    value: 'Europe/Bucharest'
  },
  {
    text: '(GMT+3) Helsinki',
    value: 'Europe/Helsinki'
  },
  {
    text: '(GMT+3) Istanbul',
    value: 'Europe/Istanbul'
  },
  {
    text: '(GMT+3) Jerusalem',
    value: 'Asia/Jerusalem'
  },
  {
    text: '(GMT+3) Kyiv',
    value: 'Europe/Kiev'
  },
  {
    text: '(GMT+3) Minsk',
    value: 'Europe/Minsk'
  },
  {
    text: '(GMT+3) Riga',
    value: 'Europe/Riga'
  },
  {
    text: '(GMT+3) Sofia',
    value: 'Europe/Sofia'
  },
  {
    text: '(GMT+3) Tallinn',
    value: 'Europe/Tallinn'
  },
  {
    text: '(GMT+3) Vilnius',
    value: 'Europe/Vilnius'
  },
  {
    text: '(GMT+3) Baghdad',
    value: 'Asia/Baghdad'
  },
  {
    text: '(GMT+3) Kuwait',
    value: 'Asia/Kuwait'
  },
  {
    text: '(GMT+3) Moscow',
    value: 'Europe/Moscow'
  },
  {
    text: '(GMT+3) Nairobi',
    value: 'Africa/Nairobi'
  },
  {
    text: '(GMT+3) Riyadh',
    value: 'Asia/Riyadh'
  },
  {
    text: '(GMT+3) St. Petersburg',
    value: 'Europe/Moscow'
  },
  {
    text: '(GMT+4) Volgograd',
    value: 'Europe/Volgograd'
  },
  {
    text: '(GMT+4) Abu Dhabi',
    value: 'Asia/Dubai'
  },
  {
    text: '(GMT+4) Baku',
    value: 'Asia/Baku'
  },
  {
    text: '(GMT+4) Muscat',
    value: 'Asia/Muscat'
  },
  {
    text: '(GMT+4) Tbilisi',
    value: 'Asia/Tbilisi'
  },
  {
    text: '(GMT+4) Yerevan',
    value: 'Asia/Yerevan'
  },
  {
    text: '(GMT+5) Islamabad',
    value: 'Asia/Karachi'
  },
  {
    text: '(GMT+5) Karachi',
    value: 'Asia/Karachi'
  },
  {
    text: '(GMT+5) Tashkent',
    value: 'Asia/Tashkent'
  },
  {
    text: '(GMT+6) Almaty',
    value: 'Asia/Almaty'
  },
  {
    text: '(GMT+6) Astana',
    value: 'Asia/Almaty'
  },
  {
    text: '(GMT+6) Dhaka',
    value: 'Asia/Dhaka'
  },
  {
    text: '(GMT+6) Urumqi',
    value: 'Asia/Urumqi'
  },
  {
    text: '(GMT+7) Novosibirsk',
    value: 'Asia/Novosibirsk'
  },
  {
    text: '(GMT+7) Bangkok',
    value: 'Asia/Bangkok'
  },
  {
    text: '(GMT+7) Hanoi',
    value: 'Asia/Saigon'
  },
  {
    text: '(GMT+7) Jakarta',
    value: 'Asia/Jakarta'
  },
  {
    text: '(GMT+7) Krasnoyarsk',
    value: 'Asia/Krasnoyarsk'
  },
  {
    text: '(GMT+8) Beijing',
    value: 'Asia/Harbin'
  },
  {
    text: '(GMT+8) Chongqing',
    value: 'Asia/Chongqing'
  },
  {
    text: '(GMT+8) Hong Kong',
    value: 'Asia/Hong_Kong'
  },
  {
    text: '(GMT+8) Irkutsk',
    value: 'Asia/Irkutsk'
  },
  {
    text: '(GMT+8) Kuala Lumpur',
    value: 'Asia/Kuala_Lumpur'
  },
  {
    text: '(GMT+8) Perth',
    value: 'Australia/Perth'
  },
  {
    text: '(GMT+8) Singapore',
    value: 'Singapore'
  },
  {
    text: '(GMT+8) Ulaan Bataar',
    value: 'Asia/Taipei'
  },
  {
    text: '(GMT+8) Taipei',
    value: 'Asia/Taipei'
  },
  {
    text: '(GMT+9) Seoul',
    value: 'Asia/Seoul'
  },
  {
    text: '(GMT+9) Tokyo',
    value: 'Asia/Tokyo'
  },
  {
    text: '(GMT+9) Yakutsk',
    value: 'Asia/Yakutsk'
  },
  {
    text: '(GMT+10) Brisbane',
    value: 'Australia/Brisbane'
  },
  {
    text: '(GMT+10) Canberra',
    value: 'Australia/Canberra'
  },
  {
    text: '(GMT+10) Guam',
    value: 'Pacific/Guam'
  },
  {
    text: '(GMT+10) Hobart',
    value: 'Australia/Hobart'
  },
  {
    text: '(GMT+10) Melbourne',
    value: 'Australia/Melbourne'
  },
  {
    text: '(GMT+10) Port Moresby',
    value: 'Pacific/Port_Moresby'
  },
  {
    text: '(GMT+10) Sydney',
    value: 'Australia/Sydney'
  },
  {
    text: '(GMT+10) Vladivostok',
    value: 'Asia/Vladivostok'
  },
  {
    text: '(GMT+11) Magadan',
    value: 'Asia/Magadan'
  },
  {
    text: '(GMT+11) New Caledonia',
    value: 'Pacific/Noumea'
  },
  {
    text: '(GMT+11) Solomon Is. ',
    value: 'Pacific/Guadalcanal'
  },
  {
    text: '(GMT+11) Norfolk',
    value: 'Pacific/Norfolk'
  },
  {
    text: '(GMT+12) Auckland',
    value: 'Pacific/Auckland'
  },
  {
    text: '(GMT+12) Fiji',
    value: 'Pacific/Fiji'
  },
  {
    text: '(GMT+12) Kamchatka',
    value: 'Asia/Kamchatka'
  },
  {
    text: '(GMT+12) Marshall Is.',
    value: 'Asia/Kamchatka'
  },
  {
    text: '(GMT+12) Wellington',
    value: 'Pacific/Auckland'
  },
  {
    text: "(GMT+13) Nuku'alofa",
    value: 'Pacific/Tongatapu'
  }
];
