





















import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';

import { timeZonesList } from '../../../shared/models/timezone.model';
import { setTimeZone } from '@/utils/date.util';
import { DivisionModule } from '@/store';
import { ToastHelper } from '@/utils/toast.util';
import { AdminConfService } from '@/shared/services/admin-conf/admin-conf.service';

@Component({
  components: {}
})
export default class BasicTimeZone extends Vue {
  // TODO replace with current one
  timeZone =
    this.currDivision.timeZone === 'America/Los_Angeles'
      ? 'US/Pacific'
      : this.currDivision.timeZone;

  time = moment();

  timeZonesList = timeZonesList;

  get currDivision() {
    const divisionAbbr = DivisionModule.division;
    return DivisionModule.divisionsList.find(
      item => (item.abbr = divisionAbbr)
    );
  }

  get currDivisionTimeZone() {
    return this.currDivision.timeZone === 'America/Los_Angeles'
      ? 'US/Pacific'
      : this.currDivision.timeZone;
  }

  updateTimeZone(zone) {
    console.log('Set new time zone', zone);
    this.saveTimeZone();
    setTimeZone(zone);
  }

  async saveTimeZone() {
    const updatedDivision = {
      ...this.currDivision,
      timeZone: this.timeZone
    };

    try {
      await AdminConfService.updateTimeZone('divisions', updatedDivision.id, {
        timeZone: this.timeZone
      });

      ToastHelper.show(`Admin - Basic`, 'Save successful', 5000, 'success');
    } catch (err) {
      ToastHelper.show(`Admin - Basic`, err.message, 5000, 'danger');
    }
  }
}
